@charset "UTF-8";
@font-face {
  font-family: 'iconfont';
  /* project id 713694 */
  src: url("//at.alicdn.com/t/font_713694_v6lmh9eujws.eot");
  src: url("//at.alicdn.com/t/font_713694_v6lmh9eujws.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/font_713694_v6lmh9eujws.woff") format("woff"), url("//at.alicdn.com/t/font_713694_v6lmh9eujws.ttf") format("truetype"), url("//at.alicdn.com/t/font_713694_v6lmh9eujws.svg#iconfont") format("svg"); }

.xs-font {
  font-size: 14px; }

.sm-font, .weui-search-bar__box .weui-icon-clear, .weui-icon-search,
.weui-search-bar__box .weui-search-bar__input,
.weui-tabbar__label,
.weui-search-bar__label span {
  font-size: 16px; }

.md-font {
  font-size: 18px; }

.lg-font {
  font-size: 20px; }

.lg-x-font {
  font-size: 22px; }

.lg-xx-font {
  font-size: 30px; }

.fw100 {
  font-weight: 100; }

.fw200 {
  font-weight: 200; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.fw600 {
  font-weight: 600; }

.fw700 {
  font-weight: 700; }

.fw800 {
  font-weight: 800; }

.fw900 {
  font-weight: 900; }

.color-level1 {
  color: #333333; }

.bgcolor-level1 {
  background-color: #333333; }

.color-level2 {
  color: #666666; }

.bgcolor-level2 {
  background-color: #666666; }

.color-level3 {
  color: #999999; }

.bgcolor-level3, .icon.cross:before, .icon.cross:after {
  background-color: #999999; }

.color-level4 {
  color: #fcdb88; }

.bgcolor-level4 {
  background-color: #fcdb88; }

.color-level5 {
  color: #fbfbfb; }

.bgcolor-level5 {
  background-color: #fbfbfb; }

.color-level6 {
  color: #64af71; }

.bgcolor-level6 {
  background-color: #64af71; }

.color-level7 {
  color: #39b6ee; }

.bgcolor-level7 {
  background-color: #39b6ee; }

.color-level8 {
  color: #f0eff5; }

.bgcolor-level8 {
  background-color: #f0eff5; }

.color-level9 {
  color: #eeeeee; }

.bgcolor-level9 {
  background-color: #eeeeee; }

.color-level10 {
  color: #f5000d; }

.bgcolor-level10 {
  background-color: #f5000d; }

.color-level11 {
  color: #3aaaff; }

.bgcolor-level11 {
  background-color: #3aaaff; }

.color-light {
  color: #ffffff; }

.bgcolor-light,
.bgcolor_white {
  background-color: #ffffff; }

.blank {
  background: none; }

/* icon  */
.icon.arrow,
.icon.triangle.down {
  display: inline-block; }

.icon.arrow:after {
  content: '';
  display: block;
  border-top: 2px solid #c8c8c8;
  border-right: 2px solid #c8c8c8;
  border-bottom: transparent;
  border-left: transparent;
  width: 100%;
  height: 100%; }

.icon.arrow.up:after {
  transform: rotate(-45deg); }

.icon.arrow.left:after {
  transform: rotate(225deg); }

.icon.arrow.right:after {
  transform: rotate(45deg); }

.icon.arrow.down:before {
  transform: rotate(135deg); }

.icon.arrow.down:after {
  transform: rotate(135deg); }

/* 实心向下 */
.icon.triangle.down {
  width: 0;
  height: 0;
  border-left-width: 6px;
  border-left-style: solid;
  border-left-color: transparent;
  border-right-width: 6px;
  border-right-style: solid;
  border-right-color: transparent;
  border-top-width: 12px;
  border-top-style: solid;
  border-top-color: #999999; }

.icon.cross {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px; }

.icon.cross:before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px; }

.icon.cross:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  margin-left: -1px; }

.arrow-right {
  display: inline-block;
  border-right: 4px solid #c8c8c8;
  border-bottom: 4px solid #c8c8c8;
  border-top: transparent;
  border-left: transparent;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg); }

.arrow-down {
  display: inline-block;
  border-right: 4px solid #c8c8c8;
  border-bottom: 4px solid #c8c8c8;
  border-top: transparent;
  border-left: transparent;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg); }

.icon.download {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAADo0lEQVR4Xu2bUVLbMBCGdyE8lxsUTtBygtITFE7QMEgzvBVOUDgB5Y2ZFYO5QXoCygkKJyDcgD7HZDuasRlwJEtKFFuZkV/jaPf/vLuWtDJCD9dwONzc2Ng4B4BhZb4gooMeXAHsw6gQ4hgRNYDXi5lPlFK/uvanLwAjRPzWAHCjlKojojMOvQCQUv4BgC8NlXdEtNuZ8spQBtA1cW0vR0BOgVwDchFcybfA0dHR1svLy8eQwklEd837Q4qglLL5umw1v76+/nR5eTn29dHrNXh4eLi3trb2EwA++w5c30dEMzYCAXCoTQC4n06nZ1dXVyPXf1sBGObsrvFmfu8JQO1HMZlMToqieLY53gpACHGLiAvNznoGoHWPiGg/GIAQ4hQRddgvdCUAAJj5TCl1ahJijAAd+oPB4BERNxdSDwCJAHguy3LblApGAFJKvSq7bopn5pvBYHAaUmVNAEOKYMgD0G+psix15H5v/m86ne6biqIRgCn8mflJKbUV4pDt3mUBqO0JIcaI+O51bUsDG4CZ9ToARFuuLhuAaXxm/q2U2ms+FFsKLHWq2gcA2wPMALosUrWtHAFL3g8IAZxTIKeAgUBICM0zL0hp/JwCOQVyCswSmDdHqw2Ua2beRES9Dr+IEWFSyh/MvIeIY9cGh7YX4n+0GlCJv21smxm7viEOSin1qvRtz/CeiHbaim/I+NEAmDq+lZMzEHwdNIivdR8QURFjtRkNgG0PwQTBB0CLeD1kegCq3LsHgE+WJ/MaCS4ADvEPRNS6O+0a/61/0SJAD1rVAb2UboXQ5qBL/GQy2W3b5e2tCNZUfSAAwLapMwQAj42C9/ZhPfiI7x2AZySEzqC9xScBIDKEIPHJAIgEIVh8UgAWhDCX+OQAzAlhbvFJAgiEsJD4ZAF4QlhYfNIAHBCiiE8egAVCNPErAaCeBVULKN1Btq7sQmdMKwVgHnE+/+ltMeTjXBf3ZAABnaeoy+Eunq6PjRwBOQL8zyLnFDDlVEgO+eRk1/eE+J8jIEeAgYAQojCctYt2SmzZKWFJgQsiOm7aDjknaD1tuWxBIePbTrkGnROUUurGw1+D4TEz6+jQe//JXcy8i4i6j2g60LlDRLpx8+6ynhY3nbZMTrG/Q9ZuUhsATVN3e1f+YuavSilj1LZ+L+BoeK4KmNZGqvOTmQqC/qj5w6oorvz8BwDHrs0WJwA9WFVZh4ioDxsHfcTUA7Q7Zh6VZVm4mqjat//ONZ9ug06q8QAAAABJRU5ErkJggg==");
  background-size: cover;
  background-repeat: no-repeat; }

.weui-cells {
  overflow-y: auto; }

.weui-cells.weui-cells_checkbox::before, .weui-cells.weui-cells_checkbox::after {
  display: none; }

.bottom_line::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #e5e5e5;
  color: #e5e5e5;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  z-index: 2; }

.weui-search-bar {
  flex-grow: 1; }

.weui-search-bar__box {
  padding-left: 12%;
  padding-right: 12%;
  z-index: 3; }

.weui-search-bar__box .weui-icon-search {
  position: absolute;
  left: 10px;
  top: 50%;
  line-height: 28px; }

.weui-search-bar__box .weui-icon-clear {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0 10px; }

.weui-search-bar__label {
  display: none; }

.weui-search-bar.weui-search-bar_focusing .weui-search-bar__cancel-btn {
  line-height: 200%; }

.weui-tabbar__item {
  margin-top: 20px;
  margin-bottom: 20px; }

.cells__container .weui-cells {
  background-color: transparent;
  margin-top: 0; }
  .cells__container .weui-cells .weui-cell {
    background-color: #ffffff; }
    .cells__container .weui-cells .weui-cell img {
      width: 40px;
      margin-right: 20px; }

.form__button__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; }
  .form__button__wrap .weui-btn + .weui-btn {
    margin: 0; }
  .form__button__wrap button {
    background-color: #eeeeee;
    color: #333333;
    border: 0;
    border-radius: 0;
    outline: 0; }
  .form__button__wrap button:first-of-type {
    background-color: #39b6ee;
    color: #ffffff; }

.role_container .weui-agree {
  font-size: 40px; }

.role_container .weui-agree__checkbox {
  width: 40px;
  height: 40px;
  margin-right: 20px; }

.role_container .weui-agree__text {
  color: #333333; }

.weui-picker {
  touch-action: none; }

.text-justify {
  text-align: justify;
  text-align-last: justify; }

.text-center {
  text-align: center;
  text-align-last: center; }

.text-right {
  text-align: right;
  text-align-last: right; }

.text-left {
  text-align: left;
  text-align-last: left; }

.sm-labels {
  background-color: #3aaaff;
  color: #ffffff;
  padding: 1px 8px;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.padding {
  padding: 15px; }

.padding-top {
  padding-top: 15px; }

.padding-right {
  padding-right: 15px; }

.padding-bottom {
  padding-bottom: 15px; }

.padding-left {
  padding-left: 15px; }

.margin {
  margin: 15px; }

.margin-top {
  margin-top: 15px; }

.margin-right {
  margin-right: 15px; }

.margin-bottom {
  margin-bottom: 15px; }

.margin-left {
  margin-left: 15px; }

.container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px; }

.page__container {
  background-color: #f0eff5;
  width: 100%;
  height: 100%; }

.fixed_bottom__container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2; }

.app__form__container .weui-cells__title {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 28px; }

.app__form__container .weui-cell {
  font-size: 28px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 40px; }
  .app__form__container .weui-cell .weui-label {
    width: 200px; }

.cover__popup__box {
  background-color: #ffffff;
  position: relative;
  z-index: 1000;
  border-radius: 5px; }
  .cover__popup__box .weui-flex .weui-flex__item:last-of-type {
    flex-grow: 1.6; }
  .cover__popup__box .change_meter__wrap .weui-flex .weui-flex__item {
    width: auto;
    padding: 0; }
  .cover__popup__box .button_wrap {
    display: flex;
    margin-top: 16px;
    border-top: 1px solid #eeeeee; }
    .cover__popup__box .button_wrap span {
      flex-grow: 1;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-right: 1px solid #eeeeee; }
    .cover__popup__box .button_wrap span:last-of-type {
      border-right: none; }

html, body, #root {
  width: 100%;
  height: 100%;
  min-width: 320px;
  margin: 0 auto; }

html, body {
  width: 100%; }

body {
  padding: 0;
  font-family: sans-serif;
  position: relative; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex; }

.flex-row {
  -webkit-flex-direction: row;
  flex-direction: row; }

.flex-column {
  -webkit-flex-direction: column;
  flex-direction: column; }

.justify-between {
  justify-content: space-between;
  -webkit-justify-content: space-between; }

.grow-1 {
  flex-grow: 1; }

.no-border {
  border: none; }

.no-padding {
  padding: 0; }

.no-margin {
  margin: 0; }

.no-border-radius {
  border-radius: 0; }

.full-screen {
  width: 100%;
  height: 100%; }

.lh1 {
  line-height: 1; }

.lh1_5 {
  line-height: 1.5; }

.app-container {
  width: 100%;
  height: 100%; }
  @media (min-width: 1025px) {
    .app-container {
      height: auto; } }

.avatar {
  width: 85px;
  display: inline-block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
