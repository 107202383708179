@import "../../resources/miv-sass/index.scss";
@import "../../resources/styles/vars";

.qrcode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    text-align: center;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    .qrcode_wrapper {
        width: 344px;
        height: 384px;
        padding: 22px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        flex-direction: column;
        background: #ffffff;
    }
    .qrcode_title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        line-height: 40px;
        font-size: 18px;
    }
    .qrcode_img {
        width: 300px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center; 
        background-color: #ffffff;
        border: 1px solid #f3f3f3;
        img {
            width: 100%;
            height: 100%;
        }
    }

  .qrcode_description_wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 35px;
  }
}