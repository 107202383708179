@import "vars";

.container {
  @include padding-top(20px);
  @include padding-bottom(20px);
  @include padding-left(30px);
  @include padding-right(30px);
}


.page__container {
  background-color: $color8;
  width: 100%;
  height: 100%;
}

.fixed_bottom__container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.app__form__container {
  .weui-cells__title {
    margin: 0;
    @include padding-top(20px);
    @include padding-bottom(20px);
    @include padding-left(16px);
    @include padding-right(16px);
    @include font-size(28px);
  }
  .weui-cell {
    @include font-size(28px);
    @include padding-top(20px);
    @include padding-bottom(20px);
    @include height(40px);
    .weui-label {
      @include width(200px);
    }
  }
}

.cover__popup__box {
  background-color: #ffffff;
  position: relative;
  z-index: 1000;
  border-radius: 5px;

  .weui-flex .weui-flex__item:last-of-type {
    flex-grow: 1.6;
  }
  .change_meter__wrap .weui-flex .weui-flex__item {
    width: auto;
    padding: 0;
  }
  .button_wrap {
    display: flex;
    @include margin-top(16px);
    border-top: 1px solid $color9;
    span {
      flex-grow: 1;
      text-align: center;
      @include padding-top(10px);
      @include padding-bottom(10px);
      border-right: 1px solid $color9;
    }
    span:last-of-type {
      border-right: none;
    }
  }
}

