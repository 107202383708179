.text-justify {
  text-align: justify;
  text-align-last: justify;
}

.text-center {
  text-align: center;
  text-align-last: center;
}
.text-right {
  text-align: right;
  text-align-last: right;
}
.text-left {
  text-align: left;
  text-align-last: left;
}

.sm-labels {
  background-color: $color11;
  color: #ffffff;
  padding: 1px 8px;
  line-height:1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}