/* icon  */
.icon.arrow,
.icon.triangle.down{
  display: inline-block;

}

.icon.arrow:after{
  content: '';
  display: block;
  border-top: 2px solid #c8c8c8;
  border-right: 2px solid #c8c8c8;
  border-bottom: transparent;
  border-left: transparent;
  width: 100%;
  height: 100%;
}
.icon.arrow.up:after {
  transform: rotate(-45deg);
}
.icon.arrow.left:after {
  transform: rotate(225deg);
}
.icon.arrow.right:after {
  transform: rotate(45deg);
}
.icon.arrow.down {
  &:before {
    transform: rotate(135deg);
  }
  &:after {
    transform: rotate(135deg);
  }
}
/* 实心向下 */
.icon.triangle.down {
  width: 0;
  height: 0;
  @include border-left-width(6px);
  border-left-style: solid;
  border-left-color: transparent;
  @include border-right-width(6px);
  border-right-style: solid;
  border-right-color: transparent;

  @include border-top-width(12px);
  border-top-style: solid;
  border-top-color: #999999;
}
// +
.icon.cross {
  position: absolute;
  top: 50%;
  left: 50%;
  @include width(20px);
  @include height(20px);
  @include margin-left(-10px);
  @include margin-top(-10px);
}
.icon.cross:before {
  content: "";
  @extend .bgcolor-level3;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
}
.icon.cross:after {
  @extend .bgcolor-level3;
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  margin-left: -1px;
}

.arrow-right {
  display: inline-block;
  border-right: 4px solid #c8c8c8;
  border-bottom: 4px solid #c8c8c8;
  border-top: transparent;
  border-left: transparent;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
}
.arrow-down {
  display: inline-block;
  border-right: 4px solid #c8c8c8;
  border-bottom: 4px solid #c8c8c8;
  border-top: transparent;
  border-left: transparent;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
}

.icon.download {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAADo0lEQVR4Xu2bUVLbMBCGdyE8lxsUTtBygtITFE7QMEgzvBVOUDgB5Y2ZFYO5QXoCygkKJyDcgD7HZDuasRlwJEtKFFuZkV/jaPf/vLuWtDJCD9dwONzc2Ng4B4BhZb4gooMeXAHsw6gQ4hgRNYDXi5lPlFK/uvanLwAjRPzWAHCjlKojojMOvQCQUv4BgC8NlXdEtNuZ8spQBtA1cW0vR0BOgVwDchFcybfA0dHR1svLy8eQwklEd837Q4qglLL5umw1v76+/nR5eTn29dHrNXh4eLi3trb2EwA++w5c30dEMzYCAXCoTQC4n06nZ1dXVyPXf1sBGObsrvFmfu8JQO1HMZlMToqieLY53gpACHGLiAvNznoGoHWPiGg/GIAQ4hQRddgvdCUAAJj5TCl1ahJijAAd+oPB4BERNxdSDwCJAHguy3LblApGAFJKvSq7bopn5pvBYHAaUmVNAEOKYMgD0G+psix15H5v/m86ne6biqIRgCn8mflJKbUV4pDt3mUBqO0JIcaI+O51bUsDG4CZ9ToARFuuLhuAaXxm/q2U2ms+FFsKLHWq2gcA2wPMALosUrWtHAFL3g8IAZxTIKeAgUBICM0zL0hp/JwCOQVyCswSmDdHqw2Ua2beRES9Dr+IEWFSyh/MvIeIY9cGh7YX4n+0GlCJv21smxm7viEOSin1qvRtz/CeiHbaim/I+NEAmDq+lZMzEHwdNIivdR8QURFjtRkNgG0PwQTBB0CLeD1kegCq3LsHgE+WJ/MaCS4ADvEPRNS6O+0a/61/0SJAD1rVAb2UboXQ5qBL/GQy2W3b5e2tCNZUfSAAwLapMwQAj42C9/ZhPfiI7x2AZySEzqC9xScBIDKEIPHJAIgEIVh8UgAWhDCX+OQAzAlhbvFJAgiEsJD4ZAF4QlhYfNIAHBCiiE8egAVCNPErAaCeBVULKN1Btq7sQmdMKwVgHnE+/+ltMeTjXBf3ZAABnaeoy+Eunq6PjRwBOQL8zyLnFDDlVEgO+eRk1/eE+J8jIEeAgYAQojCctYt2SmzZKWFJgQsiOm7aDjknaD1tuWxBIePbTrkGnROUUurGw1+D4TEz6+jQe//JXcy8i4i6j2g60LlDRLpx8+6ynhY3nbZMTrG/Q9ZuUhsATVN3e1f+YuavSilj1LZ+L+BoeK4KmNZGqvOTmQqC/qj5w6oorvz8BwDHrs0WJwA9WFVZh4ioDxsHfcTUA7Q7Zh6VZVm4mqjat//ONZ9ug06q8QAAAABJRU5ErkJggg==');
  background-size: cover;
  background-repeat: no-repeat;
}