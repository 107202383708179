.miv__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1; // fix 滑动几次后可滚动区域会卡住的问题
  height: 100%;
  width: 100%;
  transition: all .2s ease;
  animation-duration: .3s;
  background-color: #f0eff5;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  > .weui-cells {
    margin: 0;
  }
  .weui-select {
    padding-left: 0;
  }
}
