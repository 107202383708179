@import "../../resources/miv-sass/index.scss";
@import "../../resources/styles/vars";

.author_container {
  .author_title {
    text-align: center;
    color: $color2;
    @include padding-top(20px);
    @include padding-bottom(20px);
  }

  .weui-cells {
   margin-top: 0;

    input::-webkit-input-placeholder {
      color: $color3;
      font-weight: 200;
    }
  }
}

.other_login {
  width: 300px;
  margin: 50px auto;
  text-align: center;

  span {
    color: #999999;
  }
}