@font-face {
  font-family: 'iconfont';  /* project id 713694 */
  src: url('//at.alicdn.com/t/font_713694_v6lmh9eujws.eot');
  src: url('//at.alicdn.com/t/font_713694_v6lmh9eujws.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_713694_v6lmh9eujws.woff') format('woff'),
  url('//at.alicdn.com/t/font_713694_v6lmh9eujws.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_713694_v6lmh9eujws.svg#iconfont') format('svg');
}

.xs-font {
  // @include font-size(22px);
  font-size: 14px;
}
.sm-font {
  // @include font-size(28px);
  font-size: 16px
}
.md-font {
  // @include font-size(32px);
  font-size: 18px;
  // @media screen and (max-width:600px) {
  //   font-size: 20px;
  // }
}
.lg-font {
  // @include font-size(38px);
  font-size: 20px;
}
.lg-x-font {
  // @include font-size(42px);
  font-size: 22px;
}
.lg-xx-font {
  // @include font-size(60px);
  font-size: 30px;
}


.fw100 {
  font-weight:100;
}
.fw200 {
  font-weight: 200;
}
.fw300 {font-weight: 300;}
.fw400 {
  font-weight:400;}
.fw500 {
  font-weight:500;
}
.fw600 {
  font-weight:600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
.fw900 {
  font-weight:900;
}