@import "../../resources/styles/vars";

.payment__result__page {

  .weui-icon-success {
    color: $color7;
  }
  .icon__txt {
    margin-top: 16px;
    color: $color1;
  }

  .weui-btn.weui-btn_primary {
    background-color: $color7;
  }
}