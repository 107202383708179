@import './vars';

.color-level1 {
  color: $color1;
}
.bgcolor-level1 {
  background-color: $color1;
}
.color-level2 {
  color: $color2;
}
.bgcolor-level2 {
  background-color: $color2;
}
.color-level3 {
  color: $color3
}
.bgcolor-level3 {
  background-color: $color3
}
.color-level4 {
  color: $color4;
}
.bgcolor-level4 {
  background-color: $color4;
}
.color-level5 {
  color: $color5;
}
.bgcolor-level5{
  background-color: $color5;
}
.color-level6 {
  color: $color6;
}
.bgcolor-level6 {
  background-color: $color6;
}
.color-level7 {
  color: $color7;
}
.bgcolor-level7 {
  background-color: $color7;
}
.color-level8 {
  color: $color8;
}
.bgcolor-level8 {
  background-color: $color8;
}
.color-level9 {
  color: $color9;
}
.bgcolor-level9 {
  background-color: $color9;
}
.color-level10 {
  color: $color10;
}
.bgcolor-level10 {
  background-color: $color10;
}
.color-level11 {
  color: $color11;
}
.bgcolor-level11 {
  background-color: $color11;
}
.color-light {
  color: #ffffff;
}
.bgcolor-light,
.bgcolor_white{
  background-color: #ffffff;
}

.blank {
  background: none;
}