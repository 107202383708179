$color1: #333333 !default;
$color2: #666666 !default;
$color3: #999999 !default;
$color4: #fcdb88 !default;
$color5: #fbfbfb !default;
$color6: #64af71 !default;
$color7: #39b6ee !default;
$color8: #f0eff5 !default;
$color9: #eeeeee !default;
$color10: #f5000d !default;
$color11: #3aaaff !default;
$color12: #ffffff !default;

$page__bg_color: $color8;

$gutter: 15px !default;

@mixin font-size($value) { font-size: $value; }
@mixin width($value) { width: $value; }
@mixin height($value) { height: $value; }

@mixin margin-top($value) { margin-top: $value; }
@mixin margin-right($value) { margin-right: $value; }
@mixin margin-bottom($value) { margin-bottom: $value; }
@mixin margin-left($value) { margin-left: $value; }

@mixin padding-top($value) { padding-top: $value; }
@mixin padding-right($value) { padding-right: $value; }
@mixin padding-bottom($value) { padding-bottom: $value; }
@mixin padding-left($value) { padding-left: $value; }

@mixin border-top-width($value) { border-top-width: $value; }
@mixin border-right-width($value) { border-right-width: $value; }
@mixin border-bottom-width($value) { border-bottom-width: $value; }
@mixin border-left-width($value) { border-left-width: $value;; }

@mixin top($value) { top: $value; }
@mixin right($value) { right: $value; }
@mixin bottom($value) { bottom: $value; }
@mixin left($value) { left: $value; }