@import "vars";
.padding {  padding: $gutter;  }
.padding-top {  padding-top: $gutter;  }
.padding-right {  padding-right: $gutter;  }
.padding-bottom {  padding-bottom: $gutter;  }
.padding-left {  padding-left: $gutter;  }

.margin { margin: $gutter;  }
.margin-top {  margin-top: $gutter;  }
.margin-right {  margin-right: $gutter;  }
.margin-bottom {  margin-bottom: $gutter;  }
.margin-left {  margin-left: $gutter;  }
