
.weui-cells {
  overflow-y: auto;
}
.weui-cells.weui-cells_checkbox::before, .weui-cells.weui-cells_checkbox::after {
  display: none;
}
.bottom_line {
  &::after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #e5e5e5;
    color: #e5e5e5;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    z-index: 2;
  }
}
// search-list-page
.weui-search-bar {
  // line-height: 200%;
  flex-grow: 1
}
.weui-search-bar__box {
  padding-left: 12%;
  padding-right: 12%;
  z-index: 3;
}
.weui-search-bar__box .weui-icon-search {
  position: absolute;
  left: 10px;
  top: 50%;
  line-height: 28px;
}
.weui-search-bar__box .weui-icon-clear {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0 10px;
  @extend .sm-font;
}
.weui-icon-search,
.weui-search-bar__box .weui-search-bar__input,
.weui-tabbar__label,
.weui-search-bar__label span {
  @extend .sm-font;
}
.weui-search-bar__label {
  display: none;
}
.weui-search-bar.weui-search-bar_focusing .weui-search-bar__cancel-btn {
  line-height: 200%;
}

// tabbar
.weui-tabbar__item {
  @include margin-top(20px);
  @include margin-bottom(20px);
}

// 列表外层样式
.cells__container {
  .weui-cells {
    background-color: transparent;
    margin-top: 0;

    .weui-cell {
      background-color: #ffffff;

      img {
        @include width(40px);
        @include margin-right(20px);
      }
    }
  }
}

//表单 buttons
.form__button__wrap {
  // padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .weui-btn + .weui-btn {
    margin: 0;
  }

  button {
    background-color: $color9;
    color: $color1;
    border: 0;
    border-radius: 0;
    outline: 0;
  }
  button:first-of-type {
    background-color: $color7;
    color: #ffffff;
  }
}

// 权限选择
.role_container {
  .weui-agree {
    @include font-size(40px);
  }
  .weui-agree__checkbox {
    @include width(40px);
    @include height(40px);
    @include margin-right(20px);
  }
  .weui-agree__text {
    color: $color1;
  }
}

.weui-picker {
  touch-action: none;
}